import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import BioScanLandingLogo from "../svgs/biosxanlogolanding .svg";

/**
 * Generates a component that renders the apps start page.
 */
const Index = () => (
  <Layout className="container mx-auto font-body">
    <div className="flex items-center h-screen justify-items-center">
      <div className="flex flex-wrap w-full overflow-hidden">
        <div className="w-full overflow-hidden">
          <BioScanLandingLogo className="block w-full h-48 py-6" />
        </div>

        <div className="w-full py-6 overflow-hidden text-lg font-medium text-center text-white">
          <p className="w-full">International</p>
          <p className="w-full">Covid Vaccination</p>
          <p className="w-full">Registry</p>
        </div>

        <div className="w-full py-6 overflow-hidden text-center">
          <p className="w-full py-4 text-lg text-white">Don't Mask It.</p>
          <Link type="button" className="w-7/12 px-0 py-2 text-base text-green-600 bg-gray-100 border border-gray-100 rounded-md hover:bg-gray-200" to="/app/login">
            Prove It
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
